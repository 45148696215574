import React, { useEffect, useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import {
    InputAdornment,
    IconButton,
    Chip,
    Grid,
    Select,
    MenuItem,
    TextField,
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _, { set } from 'lodash';
import { MarginVerticalSection } from '../../styled';
import { RequiredLabel, ResizeTextField } from '../../common';
import { ErrorMessages, OperationStatus } from '../../../constants';
import { getToken, getVaspCode, isEmailValid } from '../../../utils/utils';
import { Autocomplete } from '@material-ui/lab';
import { useQuery } from '@apollo/react-hooks';
import { GET_REVIEW_LIST } from '../../../crud';

const useStyles = makeStyles(() => ({
    chipGrid: {
        '& .MuiChip-root': {
            backgroundColor: 'rgba(16, 73, 53, 0.25)',
            wordBreak: 'break-word',
            minHeight: '32px',
            whiteSpace: 'normal',
            height: 'auto',
        },
        '& .MuiChip-label': {
            overflow: 'auto',
            whiteSpace: 'normal',
        },
    },
    recipientInput: {
        '& .MuiAutocomplete-inputRoot': {
            paddingRight: '0px',
        },
    }
}));

export default function CustomVaspRecipients({ control, clearErrors }) {
    const classes = useStyles();
    const [receiver, setReceiver] = useState('');
    const [vaspList, setVaspList] = useState([]);
    const [loading, setLoading] = useState(true);

    const {
        fields: receivers,
        append: appendReceivers,
        remove: removeReceivers,
    } = useFieldArray({
        control,
        name: 'recipients.customVasps',
    });

    const token = getToken();
    if (token) {
        useQuery(GET_REVIEW_LIST, {
            variables: {
                status: [OperationStatus.Merged],
            },
            context: {
                headers: {
                    authorization: token,
                },
            },


            onCompleted: (data) => {
                const vasps = _.get(data, 'getReviewList', [])
                    .map((v) => {
                        return {
                            vasp_code: getVaspCode(v),
                            vasp_name: v.vasp_name,
                            vasp_status: v.vasp_status,
                        };
                    })
                    .filter((v) => v.vasp_status === OperationStatus.Merged && v.vasp_code);

                setVaspList(vasps);
                setLoading(false)
            },
            onError: (error) => {
                // TODO: use dialog
                alert(`getVasp error : ${JSON.stringify(error)}`);
            },
            fetchPolicy: 'no-cache',
        });
    }

    const handleClicked = () => {
        if (receiver === '') {
            return;
        }

        if (receivers.length >= 100) {
            return;
        }

        const isDuplicated = receivers.some((r) => r.value === receiver.toUpperCase());
        if (isDuplicated) {
            return;
        }
        appendReceivers({ value: receiver.toUpperCase() });
        setReceiver('');
        clearErrors('recipients.customVasps');
    };

    const renderChips = () => {
        if (receivers.length === 0) {
            return null;
        }
        return (
            <Grid container spacing={1} style={{ marginTop: '0.2em' }} className={classes.chipGrid}>
                {receivers.map((v, index) => (
                    <Grid item key={v.id}>
                        <Controller
                            as={<Chip />}
                            control={control}
                            name={`recipients.customVasps[${index}].value`}
                            defaultValue={receivers[index].value || ''}
                            id={`chip_receiver-${v.value}`}
                            label={v.value}
                            onDelete={() => {
                                removeReceivers(index);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <MarginVerticalSection>
            <RequiredLabel htmlFor="custom_vasp_recipient" value="Custom Vasp recipients" />
            <Autocomplete
                id="custom_vasp_recipient"
                loading={loading}
                className={classes.recipientInput}
                disableClearable={true}
                options={vaspList.filter((vasp) => !receivers.some((r) => r.value === vasp.vasp_code))}
                getOptionLabel={(vasp) => `[${vasp.vasp_code}] ${vasp.vasp_name}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Please input VASP code or Legal name"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClicked} disabled={receiver?.length === 0}>
                                        <AddIcon id="add_receiver_btn" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={receiver}
                        onChange={(e) => {
                            setReceiver(e.target.value);
                            clearErrors('recipients.customVasps');
                        }}
                    />
                )}
                onChange={(e, v) => {
                    if (v) {
                        setReceiver(v?.vasp_code);
                        clearErrors('recipients.customVasps');
                    } else {
                        setReceiver('');
                    }
                }}
                inputValue={receiver}
            />
            {renderChips()}
        </MarginVerticalSection>
    );
}
