import { OperationStatus, Operations, RoleId } from '../../constants';

export const isVaspCheckable = (vasp, roleId) => {
  let editableStatus = '';
  if (roleId === RoleId.ACCOUNT_MANAGER) {
    editableStatus = OperationStatus.PendingOnAcc;
  } else if (roleId === RoleId.ADMIN) {
    editableStatus = OperationStatus.PendingOnApprover;
  }

  return (
    vasp.vasp_status === editableStatus &&
    (vasp.operation === Operations.CREATE ||
      vasp.operation === Operations.UPDATE ||
      vasp.operation === Operations.APPROVE)
  );
};

export const reviewVasp = ({
  reviewVaspMutation,
  selectedVaspData,
  operation,
  token,
  setButtonLoading,
  setButtonSuccess,
  onSuccess,
  onFailed,
}) => {
  const inputs = selectedVaspData.map((vasp) => {
    if (operation === Operations.APPROVE) {
      return {
        id: vasp.id,
        operation,
        recipient_options: vasp.recipient_options,
        recipients: vasp.recipients,
      };
    }
    if (operation === Operations.RETURN) {
      return {
        id: vasp.id,
        operation,
        reason: vasp.reason,
      };
    }
    return {
      id: vasp.id,
      operation,
      reason: vasp.reason,
    };
  });
  const vaspCodes = selectedVaspData.map((vasp) => vasp.vasp_code);
  setButtonLoading(true);
  return reviewVaspMutation({
    variables: {
      input: inputs,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
  })
    .then(() => {
      setButtonLoading(false);
      setButtonSuccess(true);
      onSuccess(vaspCodes, operation);
    })
    .catch((error) => {
      setButtonLoading(false);
      onFailed(error);
    });
};
