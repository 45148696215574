import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
    TextField,
    IconButton,
    Tooltip,
    Grid,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import * as l from 'lodash/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import {
    getToken,
    getRoleId,
    navigate,
    getVaspCode,
    isVaspEditableByRole,
    parseGraphQLErrorMessage,
} from '../utils/utils';
import AuthenticatedPage from '../components/authenticated-page';
import Navigator from '../components/navigator';
import Layout from '../components/layout';
import VaspForm from '../components/vasp-form';
import { CustomDialog, ErrorDialog } from '../components/common';
import { RoleId, Operations } from '../constants';
import { VALID_FORM, GET_REVIEW, REVIEW_VASP } from '../crud';
import { reviewVasp } from '../components/approve-list/utils';
import ReviewButtons from '../components/approve-vasp/review-buttons';
import CustomVaspRecipients from '../components/common/recipients/customVaspRecipients';
import OtherEmailRecipients from '../components/common/recipients/otherEmailRecipients';
import _, { set } from 'lodash';

export default function ApproveVasp({ location }) {
    const token = getToken();
    const vaspRoleId = getRoleId(token);
    if (
        (vaspRoleId !== RoleId.ACCOUNT_MANAGER && vaspRoleId !== RoleId.ADMIN) ||
        !location ||
        !location.state
    ) {
        navigate('/');
        return '';
    }

    const id = location.state ? location.state.parameter.id : {};
    const [vaspDetail, setVaspDetail] = useState({});
    const [isNeedReason, setNeedReason] = useState(false);
    const [reason, setReason] = useState('');
    const [approveLoading, setApproveLoading] = useState(false);
    const [approveSuccess, setApproveSuccess] = useState(false);
    const [returnLoading, setReturnLoading] = useState(false);
    const [returnSuccess, setReturnSuccess] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [informDialogOpen, setInformDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [error, setError] = useState('');
    const [operation, setOperation] = useState('');

    const showReviewFailed = (e) => {
        setErrorDialogOpen(true);
        setError(parseGraphQLErrorMessage(e));
    };


    const formProps = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        resolver: undefined,
        context: undefined,
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true,
    });

    useQuery(GET_REVIEW, {
        variables: {
            id,
        },
        context: {
            headers: {
                authorization: token,
            },
        },
        onCompleted: (data) => {
            if (l.has(data, 'getReview')) {
                const reviewData = data.getReview;
                const vaspCode = getVaspCode(reviewData);
                setReason(reviewData.reason);
                setVaspDetail({
                    ...reviewData,
                    vasp_code: vaspCode,
                    vasp_fixed_ip: reviewData.vasp_fixed_ip && reviewData.vasp_fixed_ip.join(','),
                });
            } else {
                setVaspDetail({});
            }
        },
        onError: (e) => {
            setErrorDialogOpen(true);
            setError(parseGraphQLErrorMessage(e));
        },
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        formProps.reset({
            recipients: {
                customVasps: vaspDetail.recipients?.customVasps?.map((v) => ({ value: v })) || [],
                otherEmails: vaspDetail.recipients?.otherEmails?.map((v) => ({ value: v })) || [],
            }
        })
        setRecipientOptions(vaspDetail.recipient_options || {
            all: false,
            customVaspRecipients: false,
            otherEmails: false,
        });
    }, [vaspDetail]);

    const [recipientOptions, setRecipientOptions] = useState({
        all: true,
        customVaspRecipients: false,
        otherEmails: false,
    });
    const handleRecipientOptionsChange = (event) => {
        setRecipientOptions({
            ...recipientOptions,
            [event.target.name]: event.target.checked,
        });
    };

    const [reviewVaspMutation] = useMutation(REVIEW_VASP);
    const [validFormMutation] = useMutation(VALID_FORM);

    const showReviewSuccess = () => {
        setInformDialogOpen(true);
    };

    const getReviewVaspMutation = (roleId) => {
        if (roleId === RoleId.ACCOUNT_MANAGER) {
            return validFormMutation;
        }
        return reviewVaspMutation;
    };

    const handleDeleteClick = async () => {
        if (!reason) {
            setNeedReason(true);
            return;
        }
        setOperation(Operations.DELETE);
        setConfirmDialogOpen(true);
    };

    const handleApproveClick = async () => {
        setOperation(Operations.APPROVE);
        setConfirmDialogOpen(true);
    };

    const handleReturnClick = async () => {
        if (!reason) {
            setNeedReason(true);
            return;
        }
        setOperation(Operations.RETURN);
        setConfirmDialogOpen(true);
    };

    const handleEditClick = () => {
        const parameter = { id };
        navigate('/create-vasp-update-request', { state: { parameter } });
    };

    const genConfirmMessage = () => {
        const vaspCode = getVaspCode(vaspDetail);
        if (!operation) {
            return '';
        }
        if (operation === Operations.APPROVE) {
            return `You want to approve ${vaspCode} ?`;
        }
        if (operation === Operations.RETURN) {
            return `You want to return ${vaspCode} ?`;
        }
        if (operation === Operations.DELETE) {
            return `You want to delete ${vaspCode} ?`;
        }
        throw new Error('invalid operation');
    };

    const handleConfirmClick = async () => {
        if (!operation) {
            return;
        }
        if (operation === Operations.APPROVE) {
            const data = formProps.getValues();
            const otherEmails = data?.recipients?.otherEmails?.map((v) => v.value) || [];
            await reviewVasp({
                reviewVaspMutation: getReviewVaspMutation(vaspRoleId),
                selectedVaspData: [
                    {
                        ...vaspDetail,
                        recipient_options: {
                            all: recipientOptions.all,
                            customVaspRecipients: recipientOptions.customVaspRecipients,
                            otherEmails: recipientOptions.otherEmails,
                        },
                        recipients: {
                            customVasps: data?.recipients?.customVasps?.map((v) => v.value) || [],
                            otherEmails,
                        },
                    },
                ],
                operation: Operations.APPROVE,
                token,
                setButtonLoading: setApproveLoading,
                setButtonSuccess: setApproveSuccess,
                onSuccess: () => {
                    showReviewSuccess();
                    if (otherEmails.length > 0) {
                        const usedEmailHistory = JSON.parse(localStorage.getItem('usedEmailHistory') ?? '[]') || [];
                        usedEmailHistory.push(...otherEmails);
                        localStorage.setItem('usedEmailHistory', JSON.stringify(_.uniq(usedEmailHistory)));
                    }
                },
                onFailed: showReviewFailed,
            });
            return;
        }
        if (operation === Operations.RETURN) {
            await reviewVasp({
                reviewVaspMutation: getReviewVaspMutation(vaspRoleId),
                selectedVaspData: [
                    {
                        ...vaspDetail,
                        reason,
                    },
                ],
                operation: Operations.RETURN,
                token,
                setButtonLoading: setReturnLoading,
                setButtonSuccess: setReturnSuccess,
                onSuccess: showReviewSuccess,
                onFailed: showReviewFailed,
            });
            return;
        }
        if (operation === Operations.DELETE) {
            await reviewVasp({
                reviewVaspMutation: getReviewVaspMutation(vaspRoleId),
                selectedVaspData: [
                    {
                        ...vaspDetail,
                        reason,
                    },
                ],
                operation: Operations.DELETE,
                token,
                setButtonLoading: setDeleteLoading,
                setButtonSuccess: setDeleteSuccess,
                onSuccess: showReviewSuccess,
                onFailed: showReviewFailed,
            });
            return;
        }
        throw new Error('invalid operation');
    };

    const handleChangeText = (e) => {
        if (isNeedReason) {
            setNeedReason(false);
        }
        setReason(e.target.value);
    };

    const renderRecipientComponent = () => (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <div style={{ padding: 8 }}>
                    <FormLabel component="legend">
                        Notification recipients when registration is successful
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{ margin: 0 }}
                                    checked={recipientOptions.all}
                                    disabled={recipientOptions.customVaspRecipients}
                                    onChange={handleRecipientOptionsChange}
                                    name="all"
                                    color="primary"
                                />
                            }
                            label="All Sygna VASP"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recipientOptions.customVaspRecipients}
                                    disabled={recipientOptions.all}
                                    onChange={handleRecipientOptionsChange}
                                    name="customVaspRecipients"
                                    color="primary"
                                />
                            }
                            label="Custom VASP Recipients"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recipientOptions.otherEmails}
                                    onChange={handleRecipientOptionsChange}
                                    name="otherEmails"
                                    color="primary"
                                />
                            }
                            label="Other Emails"
                        />
                    </FormGroup>
                </div>
            </Grid>
            <Grid item xs={6}>
                {recipientOptions.customVaspRecipients ? <CustomVaspRecipients {...formProps} /> : null}
                {recipientOptions.otherEmails ? <OtherEmailRecipients {...formProps} /> : null}
            </Grid>
        </Grid>
    );

    const renderReasonComponent = () => {
        const isOperationValid =
            vaspDetail.operation === Operations.CREATE ||
            vaspDetail.operation === Operations.UPDATE ||
            vaspDetail.operation === Operations.APPROVE;

        const isEditable = isVaspEditableByRole(vaspDetail, vaspRoleId);
        const disabled = !(isOperationValid && isEditable);
        const required = isNeedReason;

        return (
            <>
                <TextField
                    id="reason"
                    value={reason || ''}
                    error={!!isNeedReason}
                    style={{ width: '40%' }}
                    label="Reason"
                    placeholder="Please input reason"
                    multiline
                    onChange={handleChangeText}
                    required={required}
                    disabled={disabled}
                />
                {reason && reason.length > 0 && !disabled ? (
                    <Tooltip title="clear">
                        <IconButton onClick={() => setReason('')} id="reason_clear">
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </>
        );
    };

    return (
        <Layout>
            <AuthenticatedPage>
                <div className="application">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                    </Helmet>
                </div>
                <Navigator
                    breadcrumbTexts={['Home', 'Approve VASP List', 'Approve Vasp']}
                    breadcrumbLinks={['/', '/approve-vasp-list', '/approve-vasp']}
                />
                <br />
                <div>
                    <VaspForm parent="approve-vasp" vaspDetail={vaspDetail} />
                    {renderRecipientComponent()}
                    {renderReasonComponent()}
                </div>
                <div
                    style={{
                        marginTop: '1em',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ReviewButtons
                        roleId={vaspRoleId}
                        vaspStatus={vaspDetail.vasp_status}
                        operation={vaspDetail.operation}
                        errors={vaspDetail.errors}
                        approveLoading={approveLoading}
                        approveSuccess={approveSuccess}
                        returnLoading={returnLoading}
                        returnSuccess={returnSuccess}
                        deleteLoading={deleteLoading}
                        deleteSuccess={deleteSuccess}
                        onApproveClick={handleApproveClick}
                        onReturnClick={handleReturnClick}
                        onDeleteClick={handleDeleteClick}
                        onEditClick={handleEditClick}
                    />
                </div>

                <ErrorDialog open={{ get: errorDialogOpen, set: setErrorDialogOpen }} message={error} />
                <CustomDialog
                    open={{ get: confirmDialogOpen, set: setConfirmDialogOpen }}
                    title="Confirm"
                    message={genConfirmMessage()}
                    positiveButtonText="ok"
                    onPositiveButtonClick={handleConfirmClick}
                    negativeButtonText="cancel"
                    onClose={() => {
                        setConfirmDialogOpen(false);
                    }}
                />
                <CustomDialog
                    open={{ get: informDialogOpen, set: setInformDialogOpen }}
                    title="Inform"
                    message={`${operation} success`}
                    positiveButtonText="ok"
                    onClose={() => {
                        setInformDialogOpen(false);
                        setApproveSuccess(false);
                        setReturnSuccess(false);
                        setDeleteSuccess(false);
                        navigate('/approve-vasp-list');
                    }}
                    timeout={5000}
                />
            </AuthenticatedPage>
        </Layout>
    );
}

ApproveVasp.defaultProps = {
    location: undefined,
};

ApproveVasp.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            parameter: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }),
};
