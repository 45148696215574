import React, { useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { InputAdornment, IconButton, Chip, Grid, TextField } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { MarginVerticalSection } from '../../styled';
import { RequiredLabel } from '../../common';
import { ErrorMessages } from '../../../constants';
import { isEmailValid } from '../../../utils/utils';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
    chipGrid: {
        '& .MuiChip-root': {
            backgroundColor: 'rgba(16, 73, 53, 0.25)',
            wordBreak: 'break-word',
            minHeight: '32px',
            whiteSpace: 'normal',
            height: 'auto',
        },
        '& .MuiChip-label': {
            overflow: 'auto',
            whiteSpace: 'normal',
        },
    },
    recipientInput: {
        '& .MuiAutocomplete-inputRoot': {
            paddingRight: '0px',
        },
    }
}));


export default function OtherEmailRecipients({ control, clearErrors, errors }) {
    const classes = useStyles();
    const [receiver, setReceiver] = useState('');
    const [error, setError] = useState(false);
    const usedEmails = JSON.parse(localStorage.getItem('usedEmailHistory') ?? '[]') || [];

    const {
        fields: receivers,
        append: appendReceivers,
        remove: removeReceivers,
    } = useFieldArray({
        control,
        name: 'recipients.otherEmails',
    });

    const handleClicked = () => {
        const isValidEmail = isEmailValid(receiver);
        if (!isValidEmail) {
            setError(true);
            return;
        }
        const isDuplicated = receivers.some((r) => r.value === receiver.toLowerCase());
        if (isDuplicated) {
            return;
        }
        appendReceivers({ value: receiver.toLowerCase() });
        setReceiver('');
        clearErrors('recipients.otherEmails');
    };

    const renderChips = () => {
        if (receivers.length === 0) {
            return null;
        }
        return (
            <Grid container spacing={1} style={{ marginTop: '0.2em' }} className={classes.chipGrid}>
                {receivers.map((v, index) => (
                    <Grid item key={v.id}>
                        <Controller
                            as={<Chip />}
                            control={control}
                            name={`recipients.otherEmails[${index}].value`}
                            defaultValue={receivers[index].value || ''}
                            id={`chip_receiver-${v.value}`}
                            label={v.value}
                            onDelete={() => {
                                removeReceivers(index);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <MarginVerticalSection>
            <RequiredLabel htmlFor="otherEmails" value="Other emails" />
            <Autocomplete
                freeSolo
                className={classes.recipientInput}
                options={usedEmails.filter((email) => !receivers.some((r) => r.value === email))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Input email address"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClicked} disabled={receiver?.length === 0}>
                                        <AddIcon id="add_receiver_btn" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => {
                            setReceiver(e.target.value);
                            setError(false);
                            clearErrors('recipients.otherEmails');
                        }}
                        error={error || !!errors?.recipients?.otherEmails}
                        helperText={error && ErrorMessages.InvalidEmailFormat}
                    />
                )}
                onChange={(e, v) => {
                    if (v) {
                        setReceiver(v);
                        clearErrors('recipients.otherEmails');
                    } else {
                        setReceiver('');
                    }
                }}
                inputValue={receiver}
            />
            {renderChips()}
        </MarginVerticalSection>
    );
}
