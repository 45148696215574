import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import classNames from 'classnames';

import _ from 'lodash';
import { RoleId, Operations, OperationStatus } from '../../constants';
import { isVaspEditableByRole } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonNormal: {
    width: '12em',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function ReviewButtons({
  roleId,
  vaspStatus,
  operation,
  errors,
  approveLoading,
  approveSuccess,
  returnLoading,
  returnSuccess,
  deleteLoading,
  deleteSuccess,
  onApproveClick,
  onReturnClick,
  onDeleteClick,
  onEditClick,
}) {
  const classes = useStyles();

  const approveButtonClassname = classNames(classes.buttonNormal, {
    [classes.buttonSuccess]: approveSuccess,
  });
  const returnButtonClassname = classNames(classes.buttonNormal, {
    [classes.buttonSuccess]: returnSuccess,
  });
  const deleteButtonClassname = classNames(classes.buttonNormal, {
    [classes.buttonSuccess]: deleteSuccess,
  });

  const renderApproveButton = (marginLeft = '0em') => (
    <div className={classes.wrapper}>
      <Button
        id="approve_button"
        variant="outlined"
        className={approveButtonClassname}
        style={{ width: '12em', marginLeft }}
        disabled={_.get(errors, 'vaspCode') || approveLoading || returnLoading || deleteLoading}
        onClick={(e) => onApproveClick(e)}
      >
        Approve
      </Button>
      {approveLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

  const renderReturnButton = (marginLeft = '0em') => (
    <div className={classes.wrapper}>
      <Button
        id="return_button"
        variant="outlined"
        className={returnButtonClassname}
        style={{ width: '12em', marginLeft }}
        disabled={approveLoading || returnLoading || deleteLoading}
        onClick={(e) => onReturnClick(e)}
      >
        Return
      </Button>
      {returnLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

  const renderDeleteButton = (marginLeft = '0em') => (
    <div className={classes.wrapper}>
      <Button
        id="delete_button"
        variant="outlined"
        className={deleteButtonClassname}
        style={{ width: '12em', marginLeft }}
        disabled={approveLoading || returnLoading || deleteLoading}
        onClick={(e) => onDeleteClick(e)}
      >
        Delete
      </Button>

      {deleteLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

  const renderEditButton = (marginLeft = '0em') => (
    <div className={classes.wrapper}>
      <Button
        id="edit_button"
        variant="outlined"
        style={{ width: '12em', marginLeft }}
        disabled={approveLoading || returnLoading || deleteLoading}
        onClick={(e) => onEditClick(e)}
      >
        Edit
      </Button>
    </div>
  );

  const renderAccManagerActionButtons = () => {
    if (operation === Operations.RETURN) {
      return renderReturnButton();
    }

    if (operation === Operations.CREATE || operation === Operations.UPDATE) {
      return (
        <>
          {renderApproveButton()}
          {renderReturnButton('1em')}
          {renderDeleteButton('1em')}
        </>
      );
    }

    if (vaspStatus === OperationStatus.Merged || vaspStatus === OperationStatus.Expired) {
      return renderEditButton();
    }
    return null;
  };

  const renderApproverActionButtons = () => {
    if (vaspStatus === OperationStatus.Expired) {
      return renderEditButton();
    }

    if (vaspStatus === OperationStatus.Approved || vaspStatus === OperationStatus.Merged) {
      return (
        <>
          {renderEditButton()}
          {renderReturnButton()}
          {renderDeleteButton('1em')}
        </>
      );
    }
    return (
      <>
        {renderApproveButton()}
        {renderReturnButton('1em')}
        {renderDeleteButton('1em')}
      </>
    );
  };

  const isHideButtons = vaspStatus === OperationStatus.Inactive || operation === Operations.DELETE;

  if (isHideButtons) return null;

  const isEditable = isVaspEditableByRole({ vasp_status: vaspStatus, operation }, roleId);
  if (!isEditable) {
    // 更新請求的權限判斷跟其他按鈕判斷的邏輯不同，所以只要是 merged 狀態，就算 approve 表單沒有編輯權限，還是可以前往更新請求。
    if (vaspStatus === OperationStatus.Merged || vaspStatus === OperationStatus.Expired) {
      return renderEditButton();
    }
    return null;
  }

  if (vaspStatus === OperationStatus.PendingOnVasp) {
    return renderDeleteButton();
  }

  if (roleId === RoleId.ACCOUNT_MANAGER) {
    return renderAccManagerActionButtons();
  }
  return renderApproverActionButtons();
}
